/* LoginPage.css */
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f7f7f7;
  }
  
  .login-form {
    padding: 40px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .input-group {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
  }
  
  .input-group label {
    margin-bottom: 5px;
  }
  
  .input-group input {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .login-button {
    padding: 10px 15px;
    width: 100%;
    background-color: #5c6bc0;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .login-button:hover {
    background-color: #3f51b5;
  }
  