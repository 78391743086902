/* Dashboard overall styling */
.dashboard {
    max-width: 600px;
    margin: 40px auto;
    display: flex;
    padding: 20px;
    border: 1px solid #eaeaea;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    justify-content: space-around;
  }
  
  /* Style the form elements with spacing and alignment */
  .dashboard form {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  
  /* Label styling */
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #333;
  }
  
  /* Dropdown (select) styling */
  select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f8f8f8;
    transition: border-color 0.3s;
  }
  
  select:hover,
  select:focus {
    border-color: #007bff;
  }
  
  /* Button styling */
  button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  /* Data display area */
  .display-data {
    margin-top: 20px;
    padding: 15px;
    background-color: #f1f1f1;
    border-left: 4px solid #007bff;
    font-size: 16px;
  }
  
  /* Disabled elements styling */
  select:disabled {
    background-color: #eaeaea;
    color: #999;
  }
  .display-data {
    text-align: center; /* Center the title */
    margin: 0 24%;
  }
  
  .table-container {
    margin: 0 auto; /* This will center your table */
    display: flex;
    justify-content: center; /* This ensures the table itself is centered horizontally */
  }
  
  .data-table {
    border-collapse: collapse; /* Optional: for styling */
    margin-top: 10px; /* Optional: to give some space between title and table */
  }
  
  .table-header-month,
  .table-header-data,
  .table-cell-month,
  .table-cell-data,
  .table-cell-no-data {
    border: 1px solid #ddd; /* Optional: for styling */
    text-align: left; /* Align text to the left within cells */
    padding: 8px; /* Optional: for styling */
  }
  
  .table-header-month,
  .table-header-data {
    background-color: #f2f2f2; /* Optional: for styling */
    color: #333; /* Optional: for styling */
  }
  
  /* Additional styling for no data available row */
  .table-cell-no-data {
    text-align: center;
  }
  