/* Navbar.css */
.navbar {
    background-color: #333; /* Dark background for the navbar */
    color: white;
    height: 60px;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  
  .navbar-container {
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
    display: flex; /* Align items in a row */
    justify-content: space-between; /* Distribute space between items */
    align-items: center;
  }
  
  .navbar-logo {
    font-size: 1.5rem;
    font-weight: bold;
    color: white;
    cursor: pointer;
  }
  .navbar-logout-button {
    padding: 8px 16px;
    background-color: #f44336; /* Example color for logout button */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
  }
  
  .navbar-logout-button:hover {
    background-color: #d32f2f; /* Darker shade for hover effect */
  }